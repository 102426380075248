import React from "react"
import MainNav from "../components/mainnav"
import { Link } from "gatsby"

const VirtualPage = () => (
  <div className="md:bg-white bg-white text-black pb-0">
    <div className="container max-w-full md:static sticky top-0 mb-10 mx-auto items-center text-center border-b-1 border-sand shadow-2xl">
      <MainNav />
    </div>
    <div className="container h-full lg:w-2/4 w-full mx-auto border-t-8 border-r-8 border-l-8 border-sand rounded-t-lg shadow-2xl font-serif">
      <Link to="https://www.facebook.com/groups/797299467745991">
        <img
          src="../../video-thumbnail.png"
          alt="Main"
          className="object-contain w-2/3 mt-5 mx-auto border-0 border-white rounded-lg justify-center mb-10"
        ></img>
      </Link>
      <div className="container overflow-auto mb-10 mx-auto items-center justify-center border-8 border-white rounded-lg shadow-2xl">
        <p className="text-center md:text-5xl text-4xl">Livestream Details</p>
        <p className="text-center mt-0 text-4xl lg:mb-0 mb-10">__</p>
        <p className="mx-auto px-10 mt-5 text-lg">
          We’ve decided there is just no way that we can possibly have the
          wedding of our dreams with everyone six-feet apart. So, let’s be even
          further apart, but feel closer together. Since we can’t have the
          wedding we originally planned, we’re going to meet online on our same
          wedding date for a virtual celebration. We’ll be at the{" "}
          <Link
            to="https://www.nps.gov/nama/planyourvisit/dc-war-memorial.htm"
            className="text-ocean hover:underline"
          >
            {" "}
            DC War Memorial
          </Link>
          , and you can be on your couch in pajamas (or a ball gown). We sure
          hope you’ll join us in making our day as special as it can be.
        </p>
        <p className="mt-16 mx-auto text-center px-10 mt-5 text-xl italic font-semibold">
          Friday, October 16, 2020 at 5:00 PM
        </p>
        <p className="mx-auto text-center px-10 mt-1 text-xl">on our</p>
        <p className="mb-16 mx-auto text-center px-10 mt-1 text-xl font-semibold">
          <Link
            to="https://www.facebook.com/groups/797299467745991"
            className="hover:underline text-ocean"
          >
            Facebook Private Group
          </Link>
        </p>
        <p className="mx-auto px-10 text-lg">
          To view our ceremony, login or create a facebook account. When you
          click the link above, you will be asked to "Request Access". Please do
          this at your earliest convenience as we will be counting all the
          requests as RSVPs. Please feel free to leave comments inside the
          group, ask questions, and post photos. After the wedding, we will save
          the livestream!
        </p>
        <p className="mx-auto my-10 px-10 text-lg text-center">
          Don't forget to use our hashtag!!!
          <p className="mx-auto mt-2 mb-10 px-10 text-lg text-center font-semibold">
            <Link
              to="https://www.facebook.com/groups/797299467745991"
              className="hover:underline text-ocean text-xl"
            >
              #YallaEverAfter
            </Link>
          </p>
        </p>
      </div>
      <div className="container overflow-auto mb-10 mx-auto items-center justify-center border-8 border-white rounded-lg shadow-2xl">
        <p className="text-center md:text-3xl text-2xl">Rehearsal (Optional)</p>
        <p className="text-center mt-0 text-3xl lg:mb-0 mb-10">__</p>
        <p className="mx-auto px-10 my-5 text-lg text-center italic">
          Saturday, October 3, 2020 at 5:00 PM
        </p>
        <p className="mx-auto px-10 mt-1 text-lg">
          Please join us for an optional rehearsal. This will help us test our
          equipment as well as give you a chance to make sure you are able to
          connect to the stream and hear everything. If any issues arise we can
          address them during this rehearsal.
        </p>
        <p className="mx-auto px-10 my-3 text-lg">
          On the date & time above, please go to our private facebook group and
          we will begin a live video at 5:00 PM.
        </p>
      </div>
      <div className="container overflow-auto mx-auto items-center justify-center border-8 border-white rounded-lg shadow-2xl">
        <p className="text-center md:text-3xl text-2xl">FAQ</p>
        <p className="text-center mt-0 text-3xl lg:mb-0 mb-10">__</p>
        <p className="mx-auto px-10 mt-3 text-lg font-semibold">
          Q: What should I wear?
        </p>
        <p className="mx-auto px-10 mt-1 text-lg italic mb-6">
          A: Nobody will be able to see you since you are just tuning in to a
          Facebook livestream (this is not a Zoom meeting!). Feel free to wear
          whatever you like. If you want to dress for success, let us see by
          taking a pic or recording a video and posting to the group. (And don't
          forget to use the hashtag!!)
        </p>
        <p className="mx-auto px-10 mt-3 text-lg font-semibold">
          Q: Will you see my comments during the livestream?
        </p>
        <p className="mx-auto px-10 mt-1 text-lg italic mb-6">
          A: The livestream will record itself including all the likes,
          reactions, and comments. We won't be able to read your comments live
          during the ceremony, but we will definitely watch the video the next
          day and see all of your comments!
        </p>
        <p className="mx-auto px-10 mt-3 text-lg font-semibold">
          Q: How can I interact with others on the group?
        </p>
        <p className="mx-auto px-10 mt-1 text-lg italic mb-6">
          A: Our Facebook group acts like a virtual guest book. You can post
          your well wishes, ask questions, tag others in your posts, post
          pictures and videos, and interact with anyone else who you like.
        </p>
        <p className="mx-auto px-10 mt-3 text-lg font-semibold">
          Q: Will there be a virtual reception?
        </p>
        <p className="mx-auto px-10 mt-1 text-lg italic mb-6">
          A: No. After the ceremony, we will have a sendoff and will say a few
          words of thanks to the camera before we sign off. We won't be able to
          stay on for long due to tight scheduling. If you have something you
          would like to express, please feel free to post in the Facebook group
          or write us privately.
        </p>
        <p className="mx-auto px-10 mt-3 text-lg font-semibold">
          Q: I followed your Livestream instructions and I need technical
          assistance before the rehearsal.
        </p>
        <p className="mx-auto px-10 mt-1 text-lg italic mb-6">
          A: Please feel free to call or text us if you have any questions. The
          rehearsal session will be much more interactive, so we can definitely
          hop on a call and troubleshoot if anybody needs help.
        </p>
        <p className="text-center mt-0 text-3xl lg:mb-0 pb-10">__</p>
      </div>
    </div>
  </div>
)

export default VirtualPage
